<template>
  <router-view></router-view>
</template>

<script>
import moment from "moment";
// alert(process.env.API_URL)
//import signin from "./views/Signin.vue";
export default {
  components: {
    //signin,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_ADMIN");
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_MODERATOR");
      }

      return false;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    encodeString(string) {
      //alert("app vue called");
      return window.btoa(string);
    },
    getApiUrl() {
      return "https://peekzy.in/backend/public/";
    },
    getAssetUrl() {
      return "https://peekzy.in/backend/public/assets/";
    },
    frontEndDateFormat(date) {
      //alert(date);
      return moment(date, "YYYY-MM-DD H:i:s").format("DD-MM-YYYY");
    },
    backEndDateFormat: function (date) {
      return moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
    },
  },
};
</script>