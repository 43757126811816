import API from "../services/index.js";

export const checkadmin = {
    state () {
      return {
        admindata :null,
        roledata:[{ id: "2", role_name: "Merchant", module_name: "merchant"},
        { id: "3", role_name: "Customer", module_name: "Customer"}  ]
      }
    },
    getters : {
        getadmindata(state){
            return state.admindata
        },
        roledata(state){
            return state.roledata
        }
    },
     mutations : {
        setadmindata(state,data) {
            state.admindata =  data 
            if(data === '1'){
                state.roledata.push({ id: "4", role_name: "Admin", module_name: "employees"})
            }
        }
    },
    
    actions : {
        cureentuser({ commit }) {
            const token = "Bearer " + localStorage.getItem("token");
            API.get("/user/checkadmin", {
                headers: { Authorization: token },
            }).then((res) => {
                commit('setadmindata',res.data[0].role_id)
            }).catch((error) => {
                console.log(error);
            })
        }
    
    }
  }

