<template>
  <nav id="menu" class="fake_menu"></nav>

  <!-- <div id="preloader">
      <div data-loader="circle-side"></div>
    </div> -->
  <!-- End Preload -->

  <div :id="divId">
    <aside>
      <figure>
        <!--a href="index.html"
            ><img
              src="../../public/frontend/img/logo_sticky.svg"
              width="155"
              height="36"
              alt=""
              class="logo_sticky"
          /></a-->
        <a href="/"><h2>Travel Friend</h2></a>
      </figure>
      <slot></slot>
      <div class="copy">{{ copyrightText }}</div>
    </aside>
  </div>
  <!-- /login -->
</template>
<script>
export default {
  data() {
    return {
      divId: "login",
      bodyId: "register_bg",
      copyrightText: "©" + new Date().toLocaleString() + " Travelfriend",
    };
  },
  components: {},
  mounted() {
    document.body.id = "register_bg";
  },
};
</script>
