import axios from 'axios';

//  const API_URL = 'http://localhost:8082/';
const API_URL = 'https://peekzy.in/backend/public/';


class AuthService {
  login(user) {
    //   alert(user.username + user.password);

    return axios
      .post(API_URL + 'user/login',
        {
          username: user.username,
          password: user.password,
          grant_type: "password",

        },
        {
          auth: {
            username: "testclient",
            password: "testsecret",
          }
        }

      )
      .then(res => {
        //   console.log(res.data);
        if (res.data.access_token && res.data.role_id != 2 && res.data.role_id != 3) {
          localStorage.setItem('token', res.data.access_token);
          localStorage.setItem('role_id', res.data.role_id);
        }
        return res.data;
      });
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('role_id');
  }

  register(user) {
    return axios.post(API_URL + 'user/register', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}

export default new AuthService();