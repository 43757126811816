import { createRouter, createWebHistory } from 'vue-router'
import Signin from "@/views/Signin";

const Dashboard = () => import("./views/home/Dashboard.vue")
const Profile = () => import("./views/users/Profile.vue")
const Users = () => import("./views/users/Users.vue")
const Adduser = () => import("./views/users/Adduser.vue")
const Createoffer = () => import("./views/offers/Createoffer.vue")
const Viewuser = () => import("./views/users/Viewuser.vue")
const Edituser = () => import("./views/users/Edituser.vue")
const Roles = () => import("./views/roles/Roles.vue")
const Addrole = () => import("./views/roles/Addrole.vue")
const Editrole = () => import("./views/roles/Editrole.vue")
const Viewrole = () => import("./views/roles/Viewrole.vue")
const Categories = () => import("./views/categories/Categories.vue")
const Addcategories = () => import("./views/categories/Addcategories.vue")
const Editcategories = () => import("./views/categories/Editcategories.vue")
const Viewcategory = () => import("./views/categories/Viewcategory.vue")
const OffersList = () => import("./views/offers/Offerslist.vue")
const Offersview = () => import("./views/offers/Offersview.vue")
const RequirementsList = () => import("./views/requirement/Requirementslist.vue")
const TravellerList = () => import("./views/traveller/Travellerlist.vue")
const Requirement = () => import("./views/requirement/Createrequirement.vue")
const Banner = () => import("./views/banner/Createbanner.vue")
const ReviewList = () => import("./views/offers/ReviewList.vue")
const Updateoffercategories = () => import("./views/offers/Editoffer.vue")
const Updatetraveller = () => import("./views/offers/Edittraveller.vue")
const Updatereview = () => import("./views/offers/Editreview.vue")
const Reviewuser = () => import("./views/users/Reviewuser.vue")
const Notification = () => import("./views/alert/Notification.vue")
const Message = () => import("./views/alert/Message.vue")
const Bannerlist = () => import("./views/banner/BannerList.vue")
const Updatebanner = () => import("./views/banner/Updatebanner.vue")
const Setting = () => import("./views/settings/Setting.vue")
const Packagelist = () => import("./views/package/Packagelist.vue")
const Packageadd = () => import("./views/package/Packageadd.vue")
const Packageupdate = () => import("./views/package/Packageupdate.vue")
const Journey = () => import("./views/traveller/Createjourney.vue")


const routes = [
  {
    path: "/create-requirement",
    component: Requirement,
    name: "requirement",
  },
  {
    path: "/create-journey",
    component: Journey,
    name: "journey",
  },
  {
    path: "/create-banner",
    component: Banner,
    name: "banner",
  },
  {
    path: "/dashboard",
    component: Dashboard,
    name: "Dashboard",
  },
  {
    path: "/create-offer",
    component: Createoffer,
    name: "createoffer",
  },
  {
    path: "/setting",
    component: Setting,
    name: "Setting",
  },
  {
    path: "/package-add",
    component: Packageadd,
    name: "Packageadd",
  },
  {
    path: "/package-list",
    component: Packagelist,
    name: "Packagelist",
  },
    {
    path: "/package-update/:id",
    component: Packageupdate,
    name: "Packageupdate",
  },
  
  {
    path: "/my-profile",
    component: Profile,
    name: "Profile",
  },
  {
    path: "/all-categories",
    component: Categories,
    name: "Categories",
  },
  {
    path: "/all-categories/:id",
    component: Categories,
    name: "Categorieswithid",
  },
  {
    path: "/create-categories",
    component: Addcategories,
    name: "Addcategories",
  },
  {
    path: "/category-details/:id",
    component: Viewcategory,
    name: "Viewcategory",
  },
  {
    path: "/edit-category/:id",
    component: Editcategories,
    name: "Editcategories",
  },
  {
    path: "/create-role",
    component: Addrole,
    name: "Addrole",
  },
  {
    path: "/roles",
    component: Roles,
    name: "Roles",
  },
  {
    path: "/view-role-details/:id",
    component: Viewrole,
    name: "Viewrole",
  },
  {
    path: "/edit-role/:id",
    component: Editrole,
    name: "Editrole",
  },
  {
    path: "/all-users",
    component: Users,
    name: "Users",
  },
  {
    path: '/user-details/:id/:roleId',
    name: 'Viewuser',
    component: Viewuser,
  },
  {
    path: '/edit-user/:id/:roleId',
    name: 'Edituser',
    component: Edituser
  },
  {
    path: "/create-user",
    component: Adduser,
    name: "Adduser",
  },
  {
    path: "/review-user/:id/:roleId",
    component: Reviewuser,
    name: "Reviewuser",
  },
  {
    path: "/banner-list",
    component: Bannerlist,
    name: "Bannerlist",
  },
  {
    path: "/update-banner/:id",
    component: Updatebanner,
    name: "Updatebanner",
  },
  
  {
    path: "/",
    component: Signin,
    name: "Signin",
    meta: { bodyId: 'register_bg' }
  },
  {
    path: "/offer-list",
    component: OffersList,
    name: "OfferList",
  }, 
  {
    path: "/viewoffer/:id",
    component: Offersview,
    name: "Offersview",
  },
  {
    path: "/update-offer/:id",
    component: Updateoffercategories,
    name: "Updateoffercategories",
  },
  {
    path: "/update-traveller/:id",
    component: Updatetraveller,
    name: "Updatetraveller",
  },
  {
    path: "/update-review/:id",
    component: Updatereview,
    name: "Updatereview",
  },
  {
    path: "/requirements-list",
    component: RequirementsList,
    name: "RequirementList",
  }, 
  {
    path: "/notification-list",
    component: Notification,
    name: "Notification",
  },
  {
    path: "/message-list",
    component: Message,
    name: "Message",
  },
  {
    path: "/traveller-list",
    component: TravellerList,
    name: "TravellerList",
  }, 
  {
    path: "/review-list",
    component: ReviewList,
    name: "ReviewList",
  }, 
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('token');
  const roleId = localStorage.getItem('role_id');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && (!loggedIn || !roleId)) {
    next('/');
  } else {
    next();
  }
});
export default router
