<template>
  <div>
    <Registerlayout>
      <h5 class="text-center">Admin Login</h5>
      <Form @submit="handleLogin" :validation-schema="schema">
        <div class="form-group">
          <label for="username">Username</label>
          <Field name="username" type="text" class="form-control" />
          <ErrorMessage name="username" class="error-feedback" />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <Field name="password" type="password" class="form-control" />
          <ErrorMessage name="password" class="error-feedback" />
        </div>

        <div class="form-group">
          <button class="btn btn-primary btn-block" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Login</span>
          </button>
        </div>

        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
      </Form>
    </Registerlayout>
  </div>
</template>


<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Registerlayout from "../layouts/Registerlayout.vue";
export default {
  name: "Login",
  beforeMount() {
    this.checkLogin();
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Registerlayout,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Username is required!"),
      password: yup.string().required("Password is required!"),
    });

    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    checkLogin() {
      let token = localStorage.getItem("token");
      let roleId = localStorage.getItem("role_id");
      //alert(token + " " + roleId);
      if (null !== token && roleId !== null) {
        this.$router.push("dashboard");
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("role_id");
      }
    },
    handleLogin(user) {
      this.loading = true;

      this.$store.dispatch("auth/login", user).then(
        () => {
          let token = localStorage.getItem("token");
          console.log(token);
          this.loading = false;
          if (token == null) {
            this.message = "Invalid user";
          } else {
            this.$router.push("/dashboard");
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.messages) ||
            error.messages ||
            error.toString();
        }
      );
    },
  },
};
</script>

<style scoped>
@import "../assets/css/style.css";
</style>