import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store'
import "bootstrap"
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
import "./assets/vendor/bootstrap/css/bootstrap.min.css"
import { FontAwesomeIcon } from './plugins/font-awesome'
import jQuery from 'jquery';
import Toaster from '@meforma/vue-toaster';
import "./assets/css/admin.css"
import "./assets/vendor/font-awesome/css/font-awesome.min.css"
import "./assets/vendor/datatables/dataTables.bootstrap4.css"
import "./assets/vendor/dropzone.css"
import "./assets/css/custom.css"
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import CKEditor from '@ckeditor/ckeditor5-vue';

createApp(App)
    .use(store)
    .use(router)
    .use(CKEditor)
    .use(VueSweetalert2)
    .use(jQuery)
    .use(Toaster)
    .use(SimpleTypeahead)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount('#app')
