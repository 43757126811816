import { createStore } from 'vuex'
import { auth } from './auth.module'
import { checkadmin} from './checkadmin.js'
const store = createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    checkadmin
  }
})

export default store;
